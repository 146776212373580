import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { AngularCsv } from 'angular7-csv/dist/Angular-csv';
import { usersService } from '../../services/users.service';
import { AppConstants } from '../../../../SGRE-shared/constants/app-constant';
import { LanguageService } from '@spartacus/core';
import { ExportCsvFileService, ExportFileOptions } from '@spartacus/storefront';

@Component({
  selector: 'app-csv-download',
  templateUrl: './csv-download.component.html',
  styleUrl: './csv-download.component.scss',
})
export class CSVDownloadComponent {
  translations: any = {
    es: {
      Name: 'Name',
      Username: 'Username',
      AlternativeEmail: 'Alternative Email',
      Company: 'Company',
      LegalEntities: 'Legal Entities',
    },
    en: {
      Name: 'Name',
      Username: 'Username',
      AlternativeEmail: 'Alternative Email',
      Company: 'Company',
      LegalEntities: 'Legal Entities',
    },
    de: {
      Name: 'Name',
      Username: 'Username',
      AlternativeEmail: 'Alternative Email',
      Company: 'Company',
      LegalEntities: 'Legal Entities',
    }
  };
  constructor(private router: Router,
    private userService: usersService,
    private languageService: LanguageService,
    private csvExportService: ExportCsvFileService
  ) { }

  downloadCSV() {
    let headers;
    let selectedLanguage;
    let showFlag = false;
    headers = [
      'Name',
      'Username',
      'AlternativeEmail',
      'Company',
      'LegalEntities'
    ];
    const rows = this.userService.filteredData.map(row => [row?.displayName ? row.displayName : '-', row?.email ? row.email : '-', row?.alternativeEmail ? row.alternativeEmail : '-', row?.company ? row.company : '-', row.legalEntityCount.toString()]);
    this.languageService.getActive().subscribe((lang) => {
      selectedLanguage = lang;
      const translatedArray = this.translateArray(headers, selectedLanguage);
      headers = translatedArray;
      showFlag = true;
    });
    if (showFlag) {
      const data1 = [headers, ...rows];
      const exportOptions: ExportFileOptions = {
        fileName: 'Users',
        extension: 'csv',
        type: 'text/csv'
      }
      const  separator = ';'
      this.csvExportService.download(data1, separator, exportOptions);
    }
  }

  translateArray(arr: string[], lang: 'en' | 'es' | 'de'): string[] {
    return arr.map(value => this.translations[lang][value] || value);
  }
}
